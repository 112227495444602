<template>
  <v-dialog v-model="data.dialog" persistent max-width="600px">
    <v-card>
      <v-card-title class="form-info">
        <span class="">تصدير تسجيلات الحضور والغياب</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <date-picker
                  v-model="form.date"
                  :rules="rules.date"
                  class="mb-5"
                  style="width: 100%;"
                  :placeholder="$('date')"
                  value-type="format"
                  :range="true"
                ></date-picker>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-select
                  v-model="form.stage_id"
                  :items="stages"
                  :rules="rules.stage_id"
                  clearable
                  outlined
                  item-text="name"
                  item-value="id"
                  :label="$('stage')"
                  required
                >
                </v-select>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-select
                  v-model="form.subject_id"
                  :items="subjects_filtered"
                  :rules="rules.subject_id"
                  :disabled="!form.stage_id"
                  clearable
                  outlined
                  item-text="name"
                  item-value="id"
                  :label="$('subject')"
                  required
                >
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="light"
          outlined
          @click="dialogModal(false)"
        >
          {{ $t('form.cancel') }}
        </v-btn>
        <v-btn
          color="info"
          :disabled="!valid || submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          تصدير
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from '@axios'
import DatePicker from 'vue2-datepicker'
import toast from '@/store/toast'
import * as FileSaver from 'file-saver';

export default {
  name: 'DayDialog',
  components: {
    DatePicker,
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        dialog: false,
        id: null,
        type: 'create',
      }),
    },
    dialogModal: {
      type: Function,
      default: state => {
        this.data.dialog = state
      },
    },
    translationKey: {
      type: String,
      default: () => '',
    },
  },
  emits: ['update:table'],
  data: () => ({
    submitLoading: false,
    valid: false,
    item: null,
    stages: [],
    subjects: [],
    form: {
      date: '',
      stage_id: '',
      subject_id: '',
    },
  }),
  computed: {
    subjects_filtered() {
      return this.subjects.filter(subject => subject.stage_id === this.form.stage_id)
    },
    rules() {
      const rules = {};
      rules.description = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.description`)]),
      ];
      rules.stage_id = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.stage`)]),
      ];
      rules.subject_id = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.subject`)]),
      ];
      rules.date = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.date`)]),
      ];

      return rules;
    },
  },
  async mounted() {
    const response = await axios.get('/stages')
    this.stages = response.data.data
    const subjectsResponse = await axios.get('/subjects')
    this.subjects = subjectsResponse.data.data
  },
  methods: {
    async submit() {
      try {
        this.submitLoading = true;
        if (this.$refs.form.validate() === false) {
          return;
        }
        const response = await axios.post('/days/export', this.form, { responseType: 'blob' });
        const stage = this.stages.find(s => s.id === this.form.stage_id);
        const subject = this.subjects.find(s => s.id === this.form.subject_id);
        const fileName = `تسجيلات الحضور - ${stage.name}-${subject.name}.xlsx`;
        FileSaver.saveAs(response.data, fileName);

        await toast.dispatch('success', this.$t('form.success.create'))
        this.dialogModal(false)
      } catch (error) {
        //   await toast.dispatch('error', error.response ? error.response.data.message : error.message)
      } finally {
        this.submitLoading = false;
      }
    },
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.form = {
        name: '',
      };
      this.item = null;
    },
    $(key, parameters = null) {
      return this.$t(`${this.translationKey}.modal.${key}`, parameters);
    },
  },
}
</script>

<style scoped>

</style>
