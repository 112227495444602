var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-btn',{directives:[{name:"can",rawName:"v-can",value:('day.create'),expression:"'day.create'"}],attrs:{"id":"btn1","color":"primary","dark":""},on:{"click":function($event){return _vm.dialogModal(true, 'create')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$('create'))+" ")],1),_c('v-spacer'),_c('v-btn',{directives:[{name:"can",rawName:"v-can",value:('day.create'),expression:"'day.create'"}],attrs:{"id":"btn1","color":"primary","dark":""},on:{"click":function($event){return _vm.dialogModalExport(true, 'create')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$('export'))+" ")],1)],1),_c('v-data-table',{staticClass:"dd",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"disable-filtering":true,"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'mdi-page-first',
      lastIcon: 'mdi-page-last',
      itemsPerPageOptions:[10, 20, 50, -1],
      itemsPerPageText: _vm.$t('dataTable.itemsPerPageText'),
      pageText: _vm.$t('dataTable.pageText'),
      itemsPerPageAllText: _vm.$t('dataTable.itemsPerPageAllText'),
    },"hide-default-header":!_vm.$vuetify.breakpoint.mobile},on:{"update:options":function($event){_vm.options=$event},"update:page":_vm.fetch,"update:items-per-page":_vm.fetch},scopedSlots:_vm._u([(!_vm.$vuetify.breakpoint.mobile)?{key:"header",fn:function(ref){
    var props = ref.props;
return [_c('datatable-header',{attrs:{"props":props,"options":_vm.options,"translation-key":_vm.translationKey},on:{"update:options":function($event){_vm.options=$event},"update:filters":_vm.fetch}})]}}:null,{key:"header.actions",fn:function(ref){
    var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"item.active",fn:function(ref){
    var item = ref.item;
return [(item.active)?_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.dialogModalQr(true, 'show', item.id)}}},[_vm._v(" تسجيل الدخول جاري ")]):_c('v-chip',{attrs:{"label":"","color":"#9e9e9e"}},[_vm._v(" تسجيل الدخول متوقف ")])]}},{key:"item.attendances_count",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"label":"","small":"","color":"success"}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.attend_count)+" ")])]}}],null,true)},[_c('span',[_vm._v("عدد الحضور")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"label":"","small":"","color":"error"}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.absent_count)+" ")])]}}],null,true)},[_c('span',[_vm._v("عدد الغيابات")])]),_c('v-btn',{attrs:{"small":"","color":"info"},on:{"click":function($event){return _vm.dialogModalAttendance(true, 'attendance', item.id)}}},[_vm._v(" عرض ")])]}},{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("format_date")(item.created_at))+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"text-center",attrs:{"id":"actions"}},[(_vm.loadings.indexOf(item.id) !== -1)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(_vm.loadings.indexOf(item.id) === -1)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"dark"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[(item.active)?_c('menu-item',{attrs:{"label":"ايقاف تسجيل الحضور","color":"error","icon":"mdi-stop"},on:{"click":function($event){return _vm.updateStatus(item, false)}}}):_vm._e(),(!item.active)?_c('menu-item',{attrs:{"label":"تشغيل تسجيل الحضور","color":"success","icon":"mdi-check"},on:{"click":function($event){return _vm.updateStatus(item, true)}}}):_vm._e(),_c('menu-item',{attrs:{"label":_vm.$('update'),"color":"info","icon":"mdi-pencil"},on:{"click":function($event){return _vm.dialogModal(true, 'update', item.id)}}}),_c('menu-item',{attrs:{"label":_vm.$('delete'),"color":"error","icon":"mdi-delete"},on:{"click":function($event){return _vm.dialogModal(true, 'delete', item.id)}}})],1)],1):_vm._e()],1)]}}],null,true)}),_c('action-dialog',{attrs:{"dialog-modal":_vm.dialogModal,"data":_vm.dialogData,"translation-key":_vm.translationKey},on:{"update:table":_vm.fetch}}),_c('qr-dialog',{attrs:{"dialog-modal":_vm.dialogModal,"data":_vm.dialogDataQr,"translation-key":_vm.translationKey},on:{"update:table":_vm.fetch}}),_c('attendance-dialog',{attrs:{"dialog-modal":_vm.dialogModal,"data":_vm.dialogDataAttendance,"translation-key":_vm.translationKey},on:{"update:table":_vm.fetch}}),_c('export-dialog',{attrs:{"dialog-modal":_vm.dialogModal,"data":_vm.dialogDataExport,"translation-key":_vm.translationKey}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }